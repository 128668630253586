import React from "react"

import Layout from "../components/layout.jsx"
import SEO from "../components/seo"
import HeaderMedisign from "../components/header-medisign/header-medisign.jsx"
import ProductDescription from "../components/product-description/product-description.jsx"
import { descriptionMedisign } from "../components/product-description/products.jsx"

function getProductDescriptions(){
  const descriptions = [];
  for (const description of descriptionMedisign){
    descriptions.push(
      <ProductDescription items={description.items} color={description.color}
      layout={description.layout} title={description.title}
      img={description.image}
      description={description.description} list={description.list}
      />
    )
  }
  return descriptions;
}

const MedsysPage = () => (
  <Layout>
    <SEO title="Medisign" />
    <HeaderMedisign/>
    {getProductDescriptions()}
  </Layout>
)

export default MedsysPage
