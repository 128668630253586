import "./header-medisign.scss";
import React from "react"
import logo from "../../images/headerMedisign/logo.png"
import videoMedisign from "../../videos/Medisign.mp4"

class HeaderMedisign extends React.Component{

  state = {
    videoVisible: false,
  }

  constructor(props){
    super(props);
    this.toggleVideo = this.toggleVideo.bind(this);
  }

  componentDidMount(){
    document.getElementById('fullscreenVideo').addEventListener('fullscreenchange', (event) => {
      const el = document.getElementById("fullscreenVideo");
      if (document.fullscreenElement) {
        this.setState({
          videoVisible: true,
        });
        el.muted = false;
        el.play();
      } else {
        this.setState({
          videoVisible: false,
        });
        el.muted = true;
      }
    });
  }

  toggleVideo(){
    const el = document.getElementById("fullscreenVideo");
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
    el.muted = false;
  }

  render (){
    return (
      <article className="headerMedisign">
        <video className={this.state.videoVisible?"":"headerMedisign__hidden"}
          controls id="fullscreenVideo">
          <source src={videoMedisign} type="video/mp4"/>
        </video>

        <div className="headerMedisign__wrap">
          <img className="headerMedisign__logo" src={logo} alt="Medisign" />
          <h2 className="headerMedisign__subtitle">
            Software de administración de consentimientos informados.
          </h2>
          <div className="headerMedisign__btn-wrap">
            <button onClick={this.toggleVideo} className="headerMedisign__btn">
              Ver Demo
            </button>
          </div>
        </div>
      </article>
    )
  }

}

export default HeaderMedisign

